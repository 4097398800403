import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SearchBox from '../../legacy-components/SearchBar'
import { BIGBRAND_API } from '../../../config'
import axios from 'axios'
import './style.scss'

class Promotion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: true,
      locations: [],
    }
  }

  componentDidMount() {
    const data = this.props.data
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter } = markdownRemark

    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth < 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
    if (frontmatter.searchbox) {
      this.fetchLocations(frontmatter.vendor_id)
    }
  }

  get promationBanner() {
    const prefix = this.props.pathContext.prefix
    const { isMobile } = this.state
    const data = this.props.data
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter } = markdownRemark

    if (isMobile) {
      return <img src={frontmatter[`img_${prefix}`]} alt="img" />
    } else {
      return <img src={frontmatter[`img_${prefix}`]} alt="img" />
    }
  }

  fetchLocations = vendorsId => {
    axios.get(`${BIGBRAND_API}/vendor/${vendorsId}/locations`).then(res => {
      this.setState({ locations: res.data })
    })
  }

  render() {
    const { data, searcboxTitle } = this.props
    const { isMobile, locations } = this.state
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter } = markdownRemark
    const prefix = this.props.pathContext.prefix
    return (
      <div className="">
        <Layout isMobile={isMobile} prefix={prefix} theme="black">
          <SEO
            title={frontmatter[`seo_title_${prefix}`]}
            canonical={frontmatter[`rel_canonical_${prefix}`]}
            prefix={prefix}
            path={frontmatter.path}
            description={frontmatter[`description_${prefix}`]}
            keywords={frontmatter[`keywords_${prefix}`]}
          />
          <div className="container">
            <div className="col-12 col-md-10 container-content">
              <div className="promo-header">
                <h1
                  className="promo-header__text"
                  dangerouslySetInnerHTML={{
                    __html: frontmatter[`title_${prefix}`],
                  }}
                />
                {/* {frontmatter[`title_${prefix}`]} */}

                {/* </h1> */}
                {this.promationBanner}
              </div>
              <div className="promo-content">
                <div className="content-header">
                  <p
                    className="content-header__text"
                    dangerouslySetInnerHTML={{
                      __html: frontmatter[`description_${prefix}`],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: frontmatter[`detail_${prefix}`],
                    }}
                  />
                  {/* {this.stepOptions} */}
                </div>
              </div>
              <div className="promo-condition-box">
                <div className="container">
                  <div className="row period-container">
                    <div className="col-9 col-md-6 col-lg-5 col-xl-4 period-box">
                      <div className="box box--left">
                        <i className="flaticon-calendar" />
                      </div>
                      <div className="box--right">
                        <p>ระยะเวลาการจอง</p>
                        <div
                          className="text-box"
                          dangerouslySetInnerHTML={{
                            __html: frontmatter['book_period'],
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-6 col-lg-5 col-xl-4 period-box">
                      <div className="box box--left">
                        <i className="flaticon-rental" />
                      </div>
                      <div className="box box--right">
                        <p>ระยะเวลาการรับรถ</p>
                        <div
                          className="text-box"
                          dangerouslySetInnerHTML={{
                            __html: frontmatter['pickup_period'],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {frontmatter.searchbox && (
                <div className="promo-searchbox">
                  <p className="step-text">
                    {frontmatter[`searchbox_title_${prefix}`]
                      ? frontmatter[`searchbox_title_${prefix}`]
                      : searcboxTitle}
                  </p>
                  <div
                    className="col-md-12 px-2 py-2 p-md-0"
                    style={{ backgroundColor: '#EDEFF2', maxWidth: 500 }}
                  >
                    {frontmatter.interbrand_promotion ? (
                      <SearchBox
                        isInterBrand
                        vendorsLocations={locations}
                        brandId={frontmatter.vendor_id}
                      />
                    ) : (
                      <SearchBox isHomepage={true} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Layout>
      </div>
    )
  }
  static defaultProps = {
    searcboxTitle: 'เริ่มต้นค้นหาและจองรถเช่า',
  }
}

export default Promotion

export const query = graphql`
  query($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        path
        seo_title_th
        seo_title_en
        rel_canonical_th
        rel_canonical_en
        description_th
        description_en
        keywords_th
        keywords_en
        title_th
        title_en
        img_th
        img_en
        detail_th
        detail_en
        book_period
        pickup_period
        searchbox
        searchbox_title_th
        searchbox_title_en
        interbrand_promotion
        vendor_id
      }
    }
  }
`
